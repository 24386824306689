const data = [
  {
    title: "Douglas with Grandma Lincoln",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-1.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "Sisters giving approval to the 1st haircut",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-2.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "Me and Carolyn in my wagon",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-3.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "Our family outside the Log Church",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-4.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "Siblings",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-5.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "My preacher look",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-10.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title:
      "Grandma Siggelkow with Glen and Gloria,  Grandma Lincoln with me and my sisters",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-11.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "Grandma Lincoln and me",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-12.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "My first dog, Spot",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-13.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "Uncle Jack Blizzard baptizing me",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-13-1.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "Thanksgiving Dinner with friends at Roundup",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-14.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title:
      "Christmas Dinner down on the farm with Grandpa and Grandma and Uncle Dave",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-16.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "With cousins, Kathy and Pat, in Williams Lake",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-17.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "With Uncle Ralph's family in Williams Lake",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-17-1.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "Grandma Lincoln and us",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-18.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "Family photos with Grandma",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-19.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "Reading 'Dick & Jane' to Leslie",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-20.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "A surprised Grandma Lincoln",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-21.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "Dad's School bus gang",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-22.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "More family photos",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-23.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "Performing at Pembina Bible Camp",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-24.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "Children's camp at Pembina",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-24-1.jpg",
    rotate: "rotate-left",
    chapter: 1,
  },
  {
    title: "Opening my Meccano set present Christmas 1954",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-26.jpg",
    rotate: "rotate-middle",
    chapter: 1,
  },
  {
    title: "Outside the Church Dad built in Jarvie",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/1-27.jpg",
    rotate: "rotate-right",
    chapter: 1,
  },
  {
    title: "Our home in High River",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-1.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "Our backyard with my VW bug",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-2.jpg",
    rotate: "rotate-middle",
    chapter: 2,
  },
  {
    title: "This picture was taken shortly after we moved to High River",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-2-1.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "About 1959",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-3.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "After the bout with Rheumatic Fever",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-4.jpg",
    rotate: "rotate-middle",
    chapter: 2,
  },
  {
    title:
      "Barbie and Janice were the first foster children that stayed with us",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-5.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "Wayne, Bev, Chris & Bob the Mclean foster family",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-5-1.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "Christmas dinner with Dan and Danny Schneider",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-6.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "Glen and I taking Sharon out to BC",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-6-1.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title:
      "The last Christmas we had with Mom, Danny, Darla, Wayne, Gary, Mom, Henrietta, Aunt Annie and me",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-7.jpg",
    rotate: "rotate-middle",
    chapter: 2,
  },
  {
    title: "Christmas 1965",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-7-1.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "Uncle Cliff Christmas 1965",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-7-2.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "Uncle Max Christmas 1965",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-7-3.jpg",
    rotate: "rotate-middle",
    chapter: 2,
  },
  {
    title: "Pal",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-8.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "Teenage Cousins",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-8-1.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "Grandma and Sharon",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-9.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "Darla and Wayne's wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-10.jpg",
    rotate: "rotate-middle",
    chapter: 2,
  },
  {
    title: "Leaving the Church",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-11.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "The Wedding party",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-12.jpg",
    rotate: "rotate-right",
    chapter: 2,
  },
  {
    title: "Dad & Hilda's wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-15.jpg",
    rotate: "rotate-left",
    chapter: 2,
  },
  {
    title: "Dad & Hilda's wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/2-17.jpg",
    rotate: "rotate-middle",
    chapter: 2,
  },
  {
    title: "Eston Bible College 1967",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/3-6.jpg",
    rotate: "rotate-right",
    chapter: 3,
  },
  {
    title: "Eston Bible College",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/3-8.jpg",
    rotate: "rotate-middle",
    chapter: 3,
  },
  {
    title: "My graduating class 1969",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/3-15.jpg",
    rotate: "rotate-left",
    chapter: 3,
  },
  {
    title: "Sharon & Ron's Wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/3-16.jpg",
    rotate: "rotate-right",
    chapter: 3,
  },
  {
    title: "Children's workers Doug & Russ",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/3-17.jpg",
    rotate: "rotate-middle",
    chapter: 3,
  },
  {
    title: "Teen Time,  Wally & his boat",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/3-18.jpg",
    rotate: "rotate-left",
    chapter: 3,
  },
  {
    title: "Zion (Before)",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-6.jpg",
    rotate: "rotate-right",
    chapter: 4,
  },
  {
    title: "Zion (After)",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-8.jpg",
    rotate: "rotate-middle",
    chapter: 4,
  },
  {
    title: "Wally",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-9.jpg",
    rotate: "rotate-left",
    chapter: 4,
  },
  {
    title: "After the Dedication of Dallas",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-9-1.jpg",
    rotate: "rotate-right",
    chapter: 4,
  },
  {
    title: "Outside My House at the Zion Church",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-10.jpg",
    rotate: "rotate-middle",
    chapter: 4,
  },
  {
    title: "Picnic with Donna",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-11.jpg",
    rotate: "rotate-left",
    chapter: 4,
  },
  {
    title: "Donna",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-12.jpg",
    rotate: "rotate-right",
    chapter: 4,
  },
  {
    title: "Sadie Hawkins day",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-12-1.jpg",
    rotate: "rotate-right",
    chapter: 4,
  },
  {
    title: "Carolyn & Les's Wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-13.jpg",
    rotate: "rotate-middle",
    chapter: 4,
  },
  {
    title: "Our Wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-15.jpg",
    rotate: "rotate-left",
    chapter: 4,
  },
  {
    title: "Our Wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-18.jpg",
    rotate: "rotate-right",
    chapter: 4,
  },
  {
    title: "Donna's Family",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-20.jpg",
    rotate: "rotate-middle",
    chapter: 4,
  },
  {
    title: "Honeymoon",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-22.jpg",
    rotate: "rotate-left",
    chapter: 4,
  },
  {
    title: "Honeymoon",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/4-23.jpg",
    rotate: "rotate-right",
    chapter: 4,
  },
  {
    title: "Off to Melfort",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-2.jpg",
    rotate: "rotate-right",
    chapter: 5,
  },
  {
    title: "First Winter in Melfort",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-5.jpg",
    rotate: "rotate-middle",
    chapter: 5,
  },
  {
    title: "First Christmas in Melfort with Ron Mcdonald",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-6.jpg",
    rotate: "rotate-left",
    chapter: 5,
  },
  {
    title: "First Christmas in Melfort",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-8.jpg",
    rotate: "rotate-right",
    chapter: 5,
  },
  {
    title: "Our first house",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-9.jpg",
    rotate: "rotate-middle",
    chapter: 5,
  },
  {
    title: "Christmas harvesting our Christmas tree 1973",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-10.jpg",
    rotate: "rotate-left",
    chapter: 5,
  },
  {
    title: "Dix, Donna and about-to-be-born Denna 1973",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-11.jpg",
    rotate: "rotate-middle",
    chapter: 5,
  },
  {
    title: "Grandma Hilda & Denna",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-13.jpg",
    rotate: "rotate-left",
    chapter: 5,
  },
  {
    title: "Dad dedicating Denna",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-15.jpg",
    rotate: "rotate-right",
    chapter: 5,
  },
  {
    title: "Dad, Hilda and Boys",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-16.jpg",
    rotate: "rotate-middle",
    chapter: 5,
  },
  {
    title: "Darcy, Dallas & Dix",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/5-17.jpg",
    rotate: "rotate-left",
    chapter: 5,
  },
  {
    title: "Our Texas House",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-10.jpg",
    rotate: "rotate-right",
    chapter: 6,
  },
  {
    title: "Irving Gillett on one of my first Mexico Trips",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-18.jpg",
    rotate: "rotate-middle",
    chapter: 6,
  },
  {
    title: "SIM Trip",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-20.jpg",
    rotate: "rotate-left",
    chapter: 6,
  },
  {
    title: "Kevin Gillett",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-25.jpg",
    rotate: "rotate-right",
    chapter: 6,
  },
  {
    title: "Gerald Nygren",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-27.jpg",
    rotate: "rotate-middle",
    chapter: 6,
  },
  {
    title: "Crossing the Tropic of Cancer",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-28.jpg",
    rotate: "rotate-left",
    chapter: 6,
  },
  {
    title: "Daycee & Donna Christmas in Puebla Mexico",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-30.jpg",
    rotate: "rotate-right",
    chapter: 6,
  },
  {
    title: "Dustin Jennings",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-32.jpg",
    rotate: "rotate-middle",
    chapter: 6,
  },
  {
    title: "Dustin",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-33.jpg",
    rotate: "rotate-left",
    chapter: 6,
  },
  {
    title: "Christmas card 1977",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-35.jpg",
    rotate: "rotate-right",
    chapter: 6,
  },
  {
    title: "Christmas in Texas 1977",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/6-37.jpg",
    rotate: "rotate-middle",
    chapter: 6,
  },
  {
    title: "With my siblings at the time of Grandpa's funeral",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-1.jpg",
    rotate: "rotate-right",
    chapter: 7,
  },
  {
    title: "Denna and Dad on Deputation",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-2.jpg",
    rotate: "rotate-middle",
    chapter: 7,
  },
  {
    title: "Denna in Vancouver",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-3.jpg",
    rotate: "rotate-left",
    chapter: 7,
  },
  {
    title: "At Uncle Max's",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-4.jpg",
    rotate: "rotate-right",
    chapter: 7,
  },
  {
    title: "At Max & Annie's Farm house",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-5.jpg",
    rotate: "rotate-middle",
    chapter: 7,
  },
  {
    title: "Kingsway Missionary Institute",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-18.jpg",
    rotate: "rotate-left",
    chapter: 7,
  },
  {
    title: "Language School",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-22.jpg",
    rotate: "rotate-right",
    chapter: 7,
  },
  {
    title: "Getting ready for a Summer in Mexico",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-24.jpg",
    rotate: "rotate-right",
    chapter: 7,
  },
  {
    title: "Our Truck and Trailer ready to head south",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-25.jpg",
    rotate: "rotate-middle",
    chapter: 7,
  },
  {
    title: "The Mail boat to Livingston",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-27.jpg",
    rotate: "rotate-left",
    chapter: 7,
  },
  {
    title: "Sharing",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-28.jpg",
    rotate: "rotate-right",
    chapter: 7,
  },
  {
    title: "Cam & Debbie's house at Los Alamos",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-29.jpg",
    rotate: "rotate-middle",
    chapter: 7,
  },
  {
    title: "The Peugeot",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-30.jpg",
    rotate: "rotate-left",
    chapter: 7,
  },
  {
    title: "Buffy and Snoopy",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/7-32.jpg",
    rotate: "rotate-right",
    chapter: 7,
  },
  {
    title: "A Moose Lake Family Reunion (late 1980's?)",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/8-2.jpg",
    rotate: "rotate-right",
    chapter: 8,
  },
  {
    title: "Darcy's wedding 1988 (one of the many we missed)",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/8-6.jpg",
    rotate: "rotate-middle",
    chapter: 8,
  },
  {
    title: "Family Reunion",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/8-10.jpg",
    rotate: "rotate-left",
    chapter: 8,
  },
  {
    title: "Our Nativity Scene",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/9-1.jpg",
    rotate: "rotate-right",
    chapter: 9,
  },
  {
    title: "Buildings bombed 1980",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/9-5.jpg",
    rotate: "rotate-middle",
    chapter: 9,
  },
  {
    title: "The results of car bombs",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/9-10.jpg",
    rotate: "rotate-left",
    chapter: 9,
  },
  {
    title: "First use of Spanish was with these kids",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/10-5.jpg",
    rotate: "rotate-left",
    chapter: 10,
  },
  {
    title: "Grandpa Cliff building benches",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/11-6.jpg",
    rotate: "rotate-right",
    chapter: 11,
  },
  {
    title: "The playhouse Grandpa Cliff built",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/11-8.jpg",
    rotate: "rotate-middle",
    chapter: 11,
  },
  {
    title: "Street market",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/11-10.jpg",
    rotate: "rotate-left",
    chapter: 11,
  },
  {
    title: "Donna & her mom buying vegetables",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/11-12.jpg",
    rotate: "rotate-right",
    chapter: 11,
  },
  {
    title: "Faithful Men",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-1.jpg",
    rotate: "rotate-middle",
    chapter: 12,
  },
  {
    title: "Revival days",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-2.jpg",
    rotate: "rotate-left",
    chapter: 12,
  },
  {
    title: "Bill, Gamaliel and me",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-2-1.jpg",
    rotate: "rotate-right",
    chapter: 12,
  },
  {
    title: "Baptisms",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-3.jpg",
    rotate: "rotate-middle",
    chapter: 12,
  },
  {
    title: "Baptisms",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-4.jpg",
    rotate: "rotate-left",
    chapter: 12,
  },
  {
    title: "New auditorium",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-4-1.jpg",
    rotate: "rotate-right",
    chapter: 12,
  },
  {
    title: "Towing the pickup across Mexico",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-5.jpg",
    rotate: "rotate-middle",
    chapter: 12,
  },
  {
    title: "Bill & Irene Lindberg",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-6.jpg",
    rotate: "rotate-left",
    chapter: 12,
  },
  {
    title: "The Lindbergs, Gilletts & Siggelkows",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-7.jpg",
    rotate: "rotate-right",
    chapter: 12,
  },
  {
    title: "My three monkeys",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-15.jpg",
    rotate: "rotate-middle",
    chapter: 12,
  },
  {
    title: "Deputation in the VW van",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-16.jpg",
    rotate: "rotate-left",
    chapter: 12,
  },
  {
    title: "Traveling Canada with Jose & Gamaliel",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/12-17.jpg",
    rotate: "rotate-right",
    chapter: 12,
  },
  {
    title: "My 40th birthday Donna made me 40 penguins ",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/13-2.jpg",
    rotate: "rotate-middle",
    chapter: 13,
  },
  {
    title: "Bible Camp in Esquintla",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/13-5.jpg",
    rotate: "rotate-left",
    chapter: 13,
  },
  {
    title: "Church plant in El Salvador",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/13-7.jpg",
    rotate: "rotate-right",
    chapter: 13,
  },
  {
    title: "Pepper",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/13-10.jpg",
    rotate: "rotate-middle",
    chapter: 13,
  },
  {
    title: "Pepper & Donna",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/13-15.jpg",
    rotate: "rotate-left",
    chapter: 13,
  },
  {
    title: "Pepper & her son Winston",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/13-16.jpg",
    rotate: "rotate-right",
    chapter: 13,
  },
  {
    title: "Cliff changing the Van windows",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-1.jpg",
    rotate: "rotate-middle",
    chapter: 15,
  },
  {
    title: "Changing the windows in Texas",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-2.jpg",
    rotate: "rotate-left",
    chapter: 15,
  },
  {
    title: "Aunt Annie & Uncle Max",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-3.jpg",
    rotate: "rotate-right",
    chapter: 15,
  },
  {
    title: "Climbing the Agua volcano",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-5.jpg",
    rotate: "rotate-middle",
    chapter: 15,
  },
  {
    title: "Cliff at the top",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-6.jpg",
    rotate: "rotate-left",
    chapter: 15,
  },
  {
    title: "At the top with Daycee",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-8.jpg",
    rotate: "rotate-right",
    chapter: 15,
  },
  {
    title: "Football field in the crater",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-10.jpg",
    rotate: "rotate-middle",
    chapter: 15,
  },
  {
    title: "Fuego & Acatenango Volcanoes",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-11.jpg",
    rotate: "rotate-left",
    chapter: 15,
  },
  {
    title: "Pacaya exploding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/15-12.jpg",
    rotate: "rotate-right",
    chapter: 15,
  },
  {
    title: "Marrying Denna & Henry",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/16-2.jpg",
    rotate: "rotate-middle",
    chapter: 16,
  },
  {
    title: "The Jones's fifth wheel",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/16-5.jpg",
    rotate: "rotate-left",
    chapter: 16,
  },
  {
    title: "Fellowship with the Joneses & Nygrens",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/16-10.jpg",
    rotate: "rotate-right",
    chapter: 16,
  },
  {
    title: "Wayne with Bible School WAT team",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/16-15.jpg",
    rotate: "rotate-middle",
    chapter: 16,
  },
  {
    title: "Lunch at the Jones's with Pastor Nery & Blanca",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-2.jpg",
    rotate: "rotate-left",
    chapter: 17,
  },
  {
    title: "Sound equipment the thieves were about to remove",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-3.jpg",
    rotate: "rotate-right",
    chapter: 17,
  },
  {
    title: "El Jones tuning up a couple missionaries",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-3-1.jpg",
    rotate: "rotate-middle",
    chapter: 17,
  },
  {
    title: "First snowball fight for the Guatemalans",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-3-2.jpg",
    rotate: "rotate-left",
    chapter: 17,
  },
  {
    title: "Mr. Fixit",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-4.jpg",
    rotate: "rotate-right",
    chapter: 17,
  },
  {
    title: "Building the new NECF Auditorium",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-4-1.jpg",
    rotate: "rotate-middle",
    chapter: 17,
  },
  {
    title: "Project Manager Per",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-4-2.jpg",
    rotate: "rotate-left",
    chapter: 17,
  },
  {
    title: "Per, Steen, Henry",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-4-3.jpg",
    rotate: "rotate-right",
    chapter: 17,
  },
  {
    title: "Pastor Donna & Mrs. Donna in the new building",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-5.jpg",
    rotate: "rotate-middle",
    chapter: 17,
  },
  {
    title: "The new home for NECF",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/17-7.jpg",
    rotate: "rotate-left",
    chapter: 17,
  },
  {
    title: "Daycee & Kevin's Wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/18-1.jpg",
    rotate: "rotate-right",
    chapter: 18,
  },
  {
    title: "Brazil",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/18-3.jpg",
    rotate: "rotate-middle",
    chapter: 18,
  },
  {
    title: "Maranatha III",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/18-4.jpg",
    rotate: "rotate-left",
    chapter: 18,
  },
  {
    title: "In the Amazon",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/18-5.jpg",
    rotate: "rotate-right",
    chapter: 18,
  },
  {
    title: "Spear fishing with Richie",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/18-6.jpg",
    rotate: "rotate-middle",
    chapter: 18,
  },
  {
    title: "Buses to Guatemala",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-7.jpg",
    rotate: "rotate-left",
    chapter: 19,
  },
  {
    title: "Our Caravan",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-9.jpg",
    rotate: "rotate-right",
    chapter: 19,
  },
  {
    title: "Tampico problem",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-10.jpg",
    rotate: "rotate-middle",
    chapter: 19,
  },
  {
    title: "Fixing the brake line",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-11.jpg",
    rotate: "rotate-left",
    chapter: 19,
  },
  {
    title: "Dave and I ",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-13.jpg",
    rotate: "rotate-right",
    chapter: 19,
  },
  {
    title: "We finally made it",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-15.jpg",
    rotate: "rotate-middle",
    chapter: 19,
  },
  {
    title: "Spain with Wes Mills",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-20.jpg",
    rotate: "rotate-left",
    chapter: 19,
  },
  {
    title: "Conference in Spain",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-21.jpg",
    rotate: "rotate-right",
    chapter: 19,
  },
  {
    title: "Spain",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/19-24.jpg",
    rotate: "rotate-middle",
    chapter: 19,
  },
  {
    title: "Dustin & Alissa's Wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-1.jpg",
    rotate: "rotate-left",
    chapter: 20,
  },
  {
    title: "Family at Dustin & Alissa's wedding",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-2.jpg",
    rotate: "rotate-right",
    chapter: 20,
  },
  {
    title: "With Wes Mills",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-6.jpg",
    rotate: "rotate-middle",
    chapter: 20,
  },
  {
    title: "Conference",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-8.jpg",
    rotate: "rotate-left",
    chapter: 20,
  },
  {
    title: "Final meeting on Airport tarmac ",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-10.jpg",
    rotate: "rotate-right",
    chapter: 20,
  },
  {
    title: "Bohdan",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-15.jpg",
    rotate: "rotate-middle",
    chapter: 20,
  },
  {
    title: "Tasya in 'Hope Has Come'",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-17.jpg",
    rotate: "rotate-left",
    chapter: 20,
  },
  {
    title: "Carpenters shop",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-19.jpg",
    rotate: "rotate-middle",
    chapter: 20,
  },
  {
    title: "Manger scene",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/20-20.jpg",
    rotate: "rotate-right",
    chapter: 20,
  },
  {
    title: "Gerald, Ruth, Simon and Joel Nygren",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/14-1.jpg",
    rotate: "rotate-middle",
    chapter: 14,
  },
  {
    title: "Gerald, Ruth, Simon and Joel Nygren",
    sm: "auto",
    md: "auto",
    lg: "auto",
    color: "secondary",
    bordercolor: "dark",
    src: "/pictures/16-16.jpg",
    rotate: "rotate-right",
    chapter: 16,
  },

];

export default data;
